import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

const documentRowStyle = (record, index, defaultStyle = {}) => {
  let color = grey[500];
  let borderWidth = 0;

  const colors = {
    SENDING_DOC: orange[500],
    CHECK_TICKET: orange[500],
    SENT_DOCUMENT: green[500],
    LOAD_DOCUMENT: green[500],
    ERROR: red[500],
    BAD_TICKET: red[500],
    CHECKED_NOT_OK: red[500],
    CHECKED_OK: green[200],
  };

  if ('documentSystemStatus' in record) {
    borderWidth = 7;
    if (record.documentSystemStatus in colors) {
      color = colors[record.documentSystemStatus];
    }
  } else if ('request_status' in record) {
    borderWidth = 7;
    if (record.request_status in colors) {
      color = colors[record.request_status];
    }
  } else if ('status' in record) {
    borderWidth = 7;
    if (record.status in colors) {
      color = colors[record.status];
    }
  }

  return {
    ...defaultStyle,
    borderLeft: `${borderWidth}px solid ${color}`,
  };
};

export default documentRowStyle;
