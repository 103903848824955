import React from 'react';
import qs from 'qs';
import {
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  translate,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import resourceLocales from './locales';
import resourceConfig from './config';
import LinkToList from './LinkToList';
import exporter from '../../../../utils/exporter';
import actH from '../actH';

const ResourceIcon = BookmarkIcon;

const listStyles = {};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton
      basePath={basePath}
      to={`${basePath}/create?${
        filterValues && filterValues.overEntityIds
          ? `_docEdoHId=${filterValues.overEntityIds}`
          : ''
      }`}
    />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source="ids"/>
    <actH.Input source="overEntityIds" label="overEntityIds" />
    <TextInput source={'code'} />
    <TextInput source={'typePackage'} />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    actions={<ResourceActions />}
    exporter={exporter}>
    <Datagrid>
      <TextField source={'id'} />
      <TextField source={'code'} />
      <TextField source={'aggregateCode'} />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => {
  const defaultData = {};
  if (props.location && props.location.search && props.location.search.length > 1) {
    const params = qs.parse(props.location.search.replace(/^\?/, ''));
    if (params && params._docEdoHId) {
      defaultData.docEdoHId = parseInt(params._docEdoHId);
    }
  }
  return (
    <Create {...props}>
      <SimpleForm>
        <actH.Input source={'docEdoHId'} defaultValue={defaultData.docEdoHId} />
        <TextInput source={'aggregateCode'} />
        <TextInput source={'code'} />
      </SimpleForm>
    </Create>
  );
};

const ResourceEdit = (props) => (
  <Edit redirect={null} title={<ResourceTitle />} {...props}>
    <SimpleForm>
        <TextInput source={'aggregateCode'} />
        <TextInput source={'code'} />
    </SimpleForm>
  </Edit>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} linkType={'show'} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} linkType={'show'} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Edit: ResourceEdit,
  Create: ResourceCreate,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    // show: ResourceShow,
    icon: ResourceIcon,
    edit: ResourceEdit,
    create: ResourceCreate,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      dateInvoice: {
        type: 'dateTime',
      },
    },
  },
};
