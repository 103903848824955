import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Синхронизация',
      title: 'Синхронизация',
      executeAction: 'Отправить',
      errorMessage: 'Ошибка',
      successMessage: 'Успешно',
      fields: {
        tail: 'Вид документа',
        bin: 'БИН/ИИН организации контрагента',
        direction: 'Сортировка',
        typeDoc: 'Тип документа',
        dateFrom: 'Дата документа, от',
        dateTo: 'Дата документа, до',
        state:'Статус',
        sortBy:'Сортировка по столбцу',
        rowCount:'Количество отображаемых строк на странице',
        did: 'Значение номера документа - "точки отсчета", по которому сортируются записи',
        number: 'Номер документа',
        documentFormat: 'Формат документа',
        documentStatus: 'Статус документа',
        documentType: 'Тип документа',
        inputFormat: 'Входящий документ',
        participantInn: 'ИНН участника товарооборота',
        limit: 'Максимальное количество записей, которое вернется в качестве ответа',
        order: 'Направление сортировки',
        orderColumn: 'Название столбца, по которому будет производиться сортировка',
        orderedColumnValue: 'Значение столбца - "точки отсчета", по которому сортируются записи',
        pageDir: 'Выбор направления (вперед/назад) для пагинации',
        pg: 'Наименование товарных групп',
        senderInn: 'ИНН отправителя',
        receiverInn: 'ИНН получателя',
        status: 'Статус'
      },
      enums: {
        status:{
          DRAFT:'Черновик',
          REJECTED:'Отклонен',
          ACCEPTED:'Принят',
          СULC_RESULTS:'Расчет итогов',
          CHECK_ERROR:'Ошибка при проверке',
          W_VERIFICATION:'Ожидает',
        },
        documentFormat: {
          MANUAL: 'формат json',
          XML: 'формат xml',
          CSV: 'формат csv',
        },
        tail:{
          INBOX:'Входящие',
          OUTGOING:'Исходящие',
          DRAFT:'Черновик',
        },
        direction:{
          asc:'по возрастанию',
          desc:'по убыванию',
        },
        typeDoc:{
          ONE_DOC_TYPE: 'Акт приема передачи маркированного товара Единого дистрибьютора',
          TEN_DOC_TYPE: 'Акт приема/передачи',
          SIX_DOC_TYPE: 'Акт внутреннего перемещения',
          SEVEN_DOC_TYPE: 'Акт внутреннего перемещения Единого дистрибьютора',
        },
        sortBy:{
            status:'статус документа',
            sender_bin:'БИН/ИИН контрагента',
            receiver_bin:'БИН/ИИН контрагента'
        },
        documentStatus: {
          IN_PROGRESS: 'Проверяется',
          CHECKED_OK: 'Успешно обработан',
          CHECKED_NOT_OK: 'Обработан с ошибками',
          PROCESSING_ERROR: 'Ошибка при обработке',
          CANCELLED: 'Отгрузка отменена. Только для документа \'Отмена отгрузки\'',
          ACCEPTED: 'Отгрузка принята. Только для документа \'Отгрузка\'',
          WAIT_ACCEPTANCE: 'Ожидание приемки. Только для документа \'Отгрузка\'. Устанавливается при успешной обработке документа \'Отгрузка товара\'',
          WAIT_PARTICIPANT_REGISTRATION: 'Ожидание приемки. Только для документа \'Отгрузка\'. Устанавливается при успешной обработке документа \'Отгрузка товара\' в сторону незарегистрированного участника',
        },
        documentType: {
          LP_SHIP_GOODS: 'Отгрузка. json',
          LP_ACCEPT_GOODS: 'Приемка. json',
          EAS_CROSSBORDER_EXPORT: 'Отгрузка в ЕАЭС с признанием КИ (экспорт)'
        },
        order: {
          ASC: 'по возрастанию',
          DESC: 'по убыванию',
        },
        pageDir: {
          PREV: 'вперед',
          NEXT: 'назад',
        },
      }
    }
  }
}
