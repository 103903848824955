import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  BulkDeleteButton,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import ResourceIcon from '@material-ui/icons/Settings';
import resourceConfig from './config';
import resourceLocales from './locales';
import exporter from '../../../../utils/exporter';

const listStyles = {};

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ResourceListFilter = (props) => <Filter {...props}></Filter>;

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}>
    <Datagrid>
      <TextField source={'id'} />
      <TextField source={'uniqName'} />
      <EditButton />
      {/* <GetOmsConnection /> */}
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'sysId'} />
      <TextInput source={'tokenInfo'} />
      <TextInput source={'uniqCertParam'} />
      <TextInput source={'uniqName'} />
      <TextInput source={'defaultInn'} />
      <TextInput source={'defaultContactPerson'} />
      <TextInput source={'omsId'} />
      <TextInput source={'omsTokenValue'} />
      <TextInput source={'omsConnection'} />
      <TextInput source={'reportPath'} />
      <TextInput source={'errorPath'} />
      <NumberInput source={'irreducibleStockCount'} />
      <TextInput source={'apikey'} />
      <TextInput source={'registrationKey'} />
      <TextInput source={'address'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm>
      <TextField source={'id'} />
      <TextInput source={'sysId'} />
      <TextInput source={'tokenInfo'} />
      <TextInput source={'uniqCertParam'} />
      <TextInput source={'uniqName'} />
      <TextInput source={'defaultInn'} />
      <TextInput source={'defaultContactPerson'} />
      <TextInput source={'omsId'} />
      <TextInput source={'omsTokenValue'} />
      <TextInput source={'omsConnection'} />
      <TextInput source={'reportPath'} />
      <TextInput source={'errorPath'} />
      <TextInput source={'apikey'} />
      <TextInput source={'registrationKey'} />
      <TextInput source={'address'} />
    </SimpleForm>
  </Edit>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {},
  },
};
