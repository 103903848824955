import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Сверка',
      title: 'Сверка',
      executeAction: 'Отправить',
      resetAction: 'Сбросить',
      errorMessage: 'Ошибка',
      successMessage: 'Успешно',
      fields: {
        file: 'Файл',
        label: 'Шаблон',
      },
    }
  }
}