import React from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  // EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  SimpleShowLayout,
  Show,
  ShowButton,
  Filter,
} from 'react-admin';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import resourceLocales from './locales';
import resourceConfig from './config';
import exporter from '../../../../utils/exporter';
import PostPagination from '../../../../utils/pagination';

const ResourceIcon = BookmarkIcon;

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
  </Filter>
);

const ResourceList = ({ classes, ...props }) => (
  <List
    filters={<ResourceListFilter />}
    exporter={exporter}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<PostPagination />}
    {...props}>
    <Datagrid>
      <TextField source={'id'} />
      <TextField source={'method'} />
      <TextField source={'sendDate'} />
      <TextField source={'shortErrorMessage'} />
      <TextField source={'url'} />
      <ShowButton />
    </Datagrid>
  </List>
);

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm></SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm></SimpleForm>
  </Edit>
);

const ResourceShow = (props) => (
  <Show {...props} title={<ResourceTitle />}>
    <SimpleShowLayout>
      <TextField source={'id'} />
      <TextField source={'body'} />
      {/* <TextField source={'companyUniqName'} /> */}
      <TextField source={'creatorUsername'} />
      <TextField source={'errorMessage'} />
      <TextField source={'httpClientMess'} />
      <TextField source={'jsonBody'} />
      <TextField source={'jsonResult'} />
      <TextField source={'method'} />
      <TextField source={'sendDate'} />
      <TextField source={'shortErrorMessage'} />
      <TextField source={'token'} />
      <TextField source={'url'} />
    </SimpleShowLayout>
  </Show>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source="id" />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    // edit: ResourceEdit,
    // create: ResourceCreate,
    show: ResourceShow,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {},
  },
};
