import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      fields:{
        periodValue:'Period value'
      },
      enums: {
        // 'CUSTOM_LOAD_TASK': 'Загрузка кодов маркировки',
        MRK_INFO_UPDATE_TASK: 'Обновление статусов кодов',
        GOODS_LOAD_TASK: 'Загрузка товаров',
        DOCUMENT_LOAD_TASK: 'Синхронизация',
        CLOSE_READY_ORDER_TASK: 'Закрытие заказов',
        GET_MRK_FROM_ACTIVE_ORDER_TASK: 'Получение кодов маркировки',
        CHECK_MRK_STATUS_FROM_ORDER_TASK: 'Проверка статуса подзаказов',
        CHECK_ORDER_STATUS_TASK: 'Проверка статуса заказа',
        SEND_ACTIVE_ORDER_TASK: 'Отправка заказов',
        FTP_CHANGE_STATUS_TASK: 'смена статуса кодов маркировки (FTP)',
        FTP_LOAD_EAN_INFO_TASK: 'загрузка справочника EAN(FTP)',
        DOCUMENT_CHECKER_TASK: 'Запрос статуса документа',
        FTP_INVOICE_SUPPLIER_TASK: 'Интеграция с ЭДО (FTP Invoice)',
        QUERY_REQUEST_TASK: 'QUERY_REQUEST_TASK',
        CUSTOM_KM_LOAD_TASK: 'Загрузка кодов маркировки',
        CREATE_AND_SEND_INPUT_BY_MRK_TASK: 'Создание и отправка ввода в оборот',
        SOA_SUITE_MRK_SEND_BY_INVOICE_TASK: 'Отправка в SOA suite',
        SEND_AGGR_BY_DOC_ERP_TASK: 'Отправка агрегации из документа ERP',
        CREATE_AND_SEND_DOCUMENT_BY_DOC_ERP_TASK: 'Создание и отправка документов из ERP',
        CREATE_ORDER_BY_UPDATED_QUANTITY_KM_TASK: 'Заказ дополнительного количества КМ',
        CREATE_ORDER_BY_ARTICLE_MAP_KM_TASK: 'Автоматическое создание дополнительных заказов КМ',
        FTP_UDP_LOADER_TASK: 'Загрузка УПД с FTP',
        MRK_INFO_UPDATE_V4_TASK: 'Обогащение кодов маркировки кодами ТНВЭД',
        SEND_NEW_OUT_TASK: 'Отправка документов "Вывод из оборота"',
        MRK_STAT_UPDATE_TASK: 'Обновление кодов по внутреннему статусу',
        FTP_TORG_CHECK_DOCUMENT_TASK: 'ТОРГ проверка документов (FTP)',
        FTP_XLS_TASK: 'FTP XLS',
        CUSTOM_NEW_STATUS_FIELD_CLEANER_TASK: 'Очищение полей в КМ',
        FTP_UDP_LOADER_UPDATER_TASK: 'Обогащение УПД',
        SEND_NEW_INPUT_TASK: 'Отправка ввод в оборот для импортного производства',
        INPUT_NOTIFICATOR_TASK: 'Ввод в оборот - отправка уведомлений',
        SEND_NEW_SHIPMENT_TASK: 'Отправка документов отгрузки',
        CHANGE_COMPANY_TASK: 'Изменение юр лица',
        FTP_UCD_LOADER_UPDATER_TASK: 'Обогащение УКД',
        RECONCILIATION_REPORT_TASK: 'Отправка отчета сверки по выбывшим кодам',
        CRYPTO_TAIL_TASK: 'Очищение крипто хвоста',
        FTP_MARS_REPORT_LOADER_TASK: 'Загрузка отчета продаж из Марса',
        KM_REQUEST_ADN_SAVE_TASK: 'Запрос и сохранение КМ',
        WAREHOUSE_INFORM_TASK: 'Уведомление на склад',
        MRK_STATUS_CHANGE_HANDLING_TASK: 'Массовое обновление статусов КМ',
        FTP_INVOICE_SUPPLIER_V2_TASK: 'Интеграция с ЭДО (FTP Invoice) 2 версия',
        REMARK_DOCUMENT_CREATING_TASK: 'Создание документов перемаркировки',
        ATK_CREATION_TASK: 'Создание документов АТК',
        NOT_ENTERED_INTO_IMPORT_MRK_REPORT_TASK: 'Отчет по не введенным импортным КМ в оборот',
        FORMAT_AND_PRINT_DATAMATRIX_IN_BASE64_TASK:
          'Формирование и печать этикеток Data Matrix в формате base64',
        SEND_NK_REQUEST_TASK: 'Нац. каталог отправка заявок',
        NK_REQUEST_ERROR_FIELD_TASK:
          'Нац. каталог сбор данных по отклоненным заявкам (редактирование неизменяемых полей)',
        CONTROL_ORDERED_AND_TRANSFERRED_MC_REPORT_TASK:
          'CONTROL_ORDERED_AND_TRANSFERRED_MC_REPORT_TASK',
        ORDERED_MC_REPORT_TASK: 'ORDERED_MC_REPORT_SCHEDULER_TASK',
        FORMATION_OF_TRANSFER_DOCUMENT_TASK: 'FORMATION_OF_TRANSFER_DOCUMENT_TASK',
        OUT_NOTIFICATION_TASK: 'OUT_NOTIFICATION_TASK',
        CREATE_AND_SEND_DISAGGREGATION_TASK: 'Создание и отправка Дезагрегации',
        SEND_SETS_AGGREGATION_TASK: 'Отправка агрегации по наборам',
        PRODUCT_CHECKER_TASK: 'Проверка статуса карточки',
        FEED_CHECKER_TASK: 'Проверка статуса заявки',
        CHECK_AND_SEND_NEW_ORDERS_TASK: 'Проверка и отправка заказа на эмиссию',
        NK_MODERATION_AND_SIGN_TASK: 'Модерация и подписание карточек в НК',
        NK_GOODS_LOAD_TASK: 'Загрузка товаров из НК',
        IRREDUCIBLE_STOCK_TASK: 'Неснижаемый запас',
        NK_NOT_PUBLISHED_TASK: 'Уведомление по неопубликованным карточкам',
        NK_NOT_PUBLISHED_BY_ORDER_TASK: ' Уведомление по заказам с неопубликованными карточками',
        FTP_NON_VIRTUAL_TORG2_CHECK_TASK: 'FTP_NON_VIRTUAL_TORG2_CHECK_TASK',
        OUTS_ERR_NOTIFICATION_TASK: 'Уведомление об ошибке вывода из оборота',
        SEND_RETURN_NOTIFICATION_TASK: 'Уведомление о поставках B2C',
        EDO_ERR_NOTIFICATION_TASK: 'Оповещение об ошибках документов ЭДО',
        FTP_UPD_CREATION_TASK: 'FTP_UPD_CREATION_TASK',
        EDO_ERR_AUTOCHECK_TASK: 'EDO_ERR_AUTOCHECK_TASK',
        EDO_ERR_AUTOSEND_TASK: 'EDO_ERR_AUTOSEND_TASK',
        FTP_UCD_NO_MARKING_TASK: 'УПД без маркировки',
        CERT_AUTO_ADD_TASK: 'CERT_AUTO_ADD_TASK',
        PDF_CREATING_TASK: 'PDF_CREATING_TASK',
        AUTO_COUNTER_INPUTP_TASK:'AUTO_COUNTER_INPUTP',
        REMOVE_FULLKM_IN_ERP: 'Очищение КМ с крипточастью в ERP',
        CLEAN_MINIO_EXPORT_FILES_TASK: 'Очищение файлов экспорта в minio',
        EAS_DOCUMENT_LOAD_TASK:'Загрузка документов EAS',
        MRK_QUERY_REQUEST_CLEARING_TASK :'Удаление запросов из журнала остатков',
        SOA_SUITE_REMARK_MRK_SEND_TASK: 'Отправка КМ перемаркировки в SOA Suite',
        CHECK_AND_SEND_ERROR_RETURNS_TASK: 'CHECK_AND_SEND_ERROR_RETURNS_TASK',
        SEND_REPORT_ON_IMPORT_ORDER_TASK: 'SEND_REPORT_ON_IMPORT_ORDER_TASK',
        CHECK_KM_STATUS_TASK: 'Проверка кодов маркировки'
      },
    },
  },
};
