import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Журнал Остатков |||| Журнал Остатков',
      mrkInput: {
        action: 'Ввод в оборот',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      soaSuiteSend: {
        action: ' Отправить в SOA Suite',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      bulkUpdate: {
        action: 'Обновление КИ',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      mrkDoc: {
        action: 'Отгрузка',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      load: {
        action: 'Загрузка',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      filters: {
        emissionDateFrom: 'Дата эмиссии с',
        emissionDateTo: 'Дата эмиссии по',
        emissionDate: 'Дата эмиссии'
      },
      updateKM: {
        action: 'Обновить',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      updateAllKM: {
        action: 'Обновить все',
        successMessage: 'Запрос отправлен',
        errorMessage: 'Ошибка',
      },
      fields: {
        id: '#',
        cis: 'Код идентификации товара',
        cises: 'Коды идентификации товара',
        gtin: 'Код товара',
        sgtin: 'Серийный номер',
        tnvedGroup: 'Группа ТНВЭД',
        ownerName: 'Наименование собственника товара',
        producerInn: 'ИИН/БИН производителя',
        producerName: 'Наименование импортера',
        ownerBin: 'БИН собственника товара',
        producerBin: 'БИН изготовителя товара',
        productName: 'Название продукта',
        mrkSystemStatus: 'Системный статус',
        status: 'Статус ИСМПТ',
        statusChangeDate: 'Дата смены статуса',
        producedDate: 'Дата вводатовара с КИ',
        сisPackageType: 'Код типа упаковки',
        name: 'Наименование товара',

        currentchldtype: 'Тип ',
        parentId: 'Родитель',
        parent: 'Код агрегата',
        prnttype: 'Тип родительского элемента',
        value: 'Код агрегата',
        emmissionDate: 'Дата эмиссии',
        username: 'Пользователь',
        ordernum: 'Номер заказа',
        orderNum: 'Номер заказа',
        tnVedCode: 'ТНВЭД',
        tnVed10: 'ТНВЭД 10',
        emissionType: 'Тип эмиссии',
        serialNumType: 'Тип серийного номера',
        serialNumber: 'Серийный номер',
        markingType: 'Тип маркировки',
        codeType: 'Тип кода',
        ownerInn: 'ИНН владельца',
        lastDocId: 'id последнего документа',
        uitCode: 'Код uit',
        documentSystemStatus: 'Системный статус документа',
        errorModelId: 'id модели ошибок',
        cryptoKey: 'Криптоключ',
        cryptoCode: 'Криптокод',
        temp: 'Временный',
        updated: 'Обновлен',
        cisPackageType: 'Тип упаковки',

        numGoods: 'Номер товара',
        invoice: 'Номер инвойса',
        dateInvoice: 'Дата инвойса',

        gtd: 'ГТД',
        dateGtd: 'Дата ГТД',

        numdoc: 'Номер ссылочного документа',
        manufacturingItem: 'Производственный код товара',
        itemCode: 'Код товара',
        utd: 'Номер УПД',
        mut: 'MUT-код',
        vendorCode: 'Артикул',
        timestampDate: 'Штамп дата-время',
        trackNumber: 'Номер трака',
        descriptionNumdoc: 'Описание ссылочного документа',
        ownerINN: 'ИНН собсвенника',
        numpos: 'Номер строки документа',
        counrty: 'Страна производитель',
        atk: 'ATK',
        invoiceAtkDate: 'Дата инвойса',
        invoiceAtk: 'Номер ссылочного документа',
        invoiceAtkPos: 'Номер позиции ссылочного документа',
        uit: 'UIT Код',
        uituCode: 'UITU Код',
        brand: 'Товарный знак',
        gtins: 'GTIN (Список)',
        statusEx: 'Статус ИСМП',
        tradeName: 'Торговое наименование товара',
        tnVedCode10: 'ТНВЭД 10',
        producerCountry: 'Страна происхождения (производства) товара',
        packageName: 'Наименование типа упаковки',
        packMaterial: 'Материал упаковки',
        color: 'Цвет',
        productSize: 'Размер',
        materialUpper: 'Материал верха',
        materialLining: 'Внутренний материал',
        productTypeDesc: 'Описание товара',
        materialDown: 'Материал низа',
        fullName: 'Полное наименование товара',
        numsGoods: 'Номер товара (Список)',
        invoices: 'Номер инвойса (Список)',
        manufacturingItems: 'Произв. код товара (Список)',
        itemCodes: 'Код товара (Список)',
        ssccs: 'SSCC (Список)',
        mrkSystemStatuses: 'Системный статус (Список)',
        statuses: 'Статус (Список)',
        ATKs: 'ATK (Список)',
        uituCodes: 'UITU Код (Список)',
        contactPerson: 'Контактное лицо',
        emissionDate: 'Дата эмиссии',
        child: 'Список дочерних КМ',
        partyNum: 'Номер производственной партии',
        limitDate: 'Срок годности и ЛС',
        dosageForm: 'Лекарственная форма',
        releaseForm: 'Форма выпуска',
        dosage: 'Дозировка',
      },
    },
  },
};
