import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        NEW: 'Новый',
        SENDED: 'Отправлен',
        SIGN: 'Подписан',
        ERROR: 'Ошибка',
        PRICE_ADDED: 'Цена добавлена',
        PRICE_NOT_ADDED: 'Цена не добавлена',
        CORR_FORMED: 'Корректировка создана',
      },
    },
  },
};
