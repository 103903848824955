export default {
  enums: [
    { id: 'DOC' },
    { id: 'OUT' },
    { id: 'INPUT' },
    { id: 'AGGR' },
    { id: 'REMARK' },
    { id: 'UPD' },
    {id: 'REPORT'},
    {id: 'ACT'},
    {id: 'IMPORT_EAEU'},
    {id: 'IMPORT_T'}
    // { id: 'ORDER'},
  ],
};
