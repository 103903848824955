import appCore from "../appCore";
import auth from "../auth";

export default async (options = {}) => {
  let {
    warehouseId = null,
    accessToken = null,
    productGroup = null,
  } = options;
  const headers = {};

  if (accessToken === null) {
    // accessToken = localStorage.getItem('accesstoken') || null;
    accessToken = await auth.getToken();
  }
  if (accessToken !== null) {
      headers['X-Auth-Token'] = `Bearer ${accessToken}`;
  }


  if (warehouseId === null) {
    warehouseId = appCore.params.warehouse || null;
  }
  if (warehouseId !== null) {
      headers['x-warehouse-name'] = warehouseId;
  }

  if (productGroup === null) {
    productGroup = appCore.params.productGroup || null;
  }
  if (productGroup !== null) {
      headers['x-user-product-group'] = productGroup;
  }

  return headers;
};
