import React from 'react';
import { translate } from  'react-admin';
import Button from '@material-ui/core/Button';

import TransitionLink from '../../../../components/TransitionLink';
import { dictionaryToParam } from '../../../../utils';
import resourceConfig from './config';

const OpenButton = ({ params, translate, type, ...props }) => {
  const urlParams = {
    ...params
  }

  let disabled = false;
  let label = `resources.${resourceConfig.name}.openAction`;

  if (props.record && type) {
    disabled = !props.record.checkPath;
  }
    urlParams.path = props.record.checkPath;

    return (
    <Button
      size={'small'}
      color={'primary'}
      {...props}
      component={TransitionLink}
      disabled={disabled}
      transitionTo={{
        params: {
          [resourceConfig.urlParamName]: dictionaryToParam(urlParams)
        }
      }}
    >
      {translate(label)}
    </Button>
)
};


export default translate(OpenButton);
