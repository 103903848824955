

export default {
  enums: [
    { id: 'NEW' },
    { id: 'SENDED' },
    { id: 'SIGN' },
    { id: 'ERROR' },
    { id: 'PRICE_ADDED' },
    { id: 'PRICE_NOT_ADDED' },
    { id: 'CORR_FORMED' },
  ]
};