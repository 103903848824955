import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции вывода из оборота |||| Позиции вывода из оборота',

      fields: {
        id: '#',
        sntin: 'Код маркировки',
      },
    },
  },
};
