import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Company settings |||| Settings',
      fields: {
        username: 'username',
        password: 'password',
        defaultInn: 'INN',
        defaultContactPerson: 'Contact person',
      },
      getOms: {
        action: 'Get oms connection',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
    },
  },
};
