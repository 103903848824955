export default {
  enums: [
    { id: "ONE_DOC_TYPE" },
    { id: "SEVEN_DOC_TYPE" },
    { id: "SIX_DOC_TYPE" },
    { id: "FOUR_DOC_TYPE" },
    { id: "FIVE_DOC_TYPE" },
    { id: "TWO_DOC_TYPE" },
    { id: "THREE_DOC_TYPE" },
    { id: "ELEVEN_DOC_TYPE" },
    { id: "SIX_THOUSAND_DOC_TYPE" },
    { id: "TEN_DOC_TYPE" },
  ],
};