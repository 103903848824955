import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DRAFT: 'DRAFT',
        REJECTED: 'REJECTED',
        W_VERIFICATION: 'W_VERIFICATION',
        ACCEPTED: 'ACCEPTED',
        CHECK_ERROR: 'CHECK_ERROR',
        WITHDRAWN: 'WITHDRAWN',
        CANCELED: 'CANCELED',
        СULC_RESULTS: 'СULC_RESULTS',
        CLARIFICATION_REQUESTED: 'CLARIFICATION_REQUESTED',
        FORMED: 'FORMED',
        IN_PROGRESS: 'IN_PROGRESS',
        CHECKED_OK: 'CHECKED_OK',
        CHECKED_NOT_OK: 'CHECKED_NOT_OK',
        ERROR: 'ERROR',
      },
    },
  },
};
