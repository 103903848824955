import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        USED_FOR_PRODUCTION: 'Использован',
        SENT_TO_PRINTER: 'Отправлен на печать',
        PRINTED: 'Напечатан',
        PRINTER_LOST: 'Утерян',
        VERIFIED: 'Подтвержден',
      },
    },
  },
};
