import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции ввоза из стран ЕАЭС |||| Позиции ввоза из стран ЕАЭС',
      fields: {
        id: '#',
        sntin: 'Код маркировки',
      },
    },
  },
};
