import React, {Component} from 'react';
import {connect} from 'react-redux';
import {translate, SimpleForm, Toolbar, SaveButton, showNotification, TextInput, NumberInput} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import qs from 'qs';
import {withRouter} from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import resourceConfig from './config';
import request from '../../../../network/request';
import {paramToDictionary} from '../../../../utils';
import EnumInput from '../../../../components/EnumInput';
import DateInput from '../../../../components/DateInput';
import moment from 'moment';


const trPrefix = `resources.${resourceConfig.name}`;

const styles = {
    label: {width: '10em', display: 'inline-block'},
    button: {margin: '1em'},
    toolbar: {background: 'none'},
    loader: {
        marginLeft: 15
    }
};


const FormToolbar = translate(({translate, ...props}) => (
    <Toolbar
        style={styles.toolbar}
        {...props}
    >
        <SaveButton
            label={translate(`${trPrefix}.executeAction`)}
            redirect={false}
            submitOnEnter={true}
        />
        {props.saving &&
            <div style={styles.loader}>
                <CircularProgress/>
            </div>
        }
    </Toolbar>
))

class AppModule extends Component {
    defaultParams = {}

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            executing: false,
            params: {
                ...this.defaultParams
            }
        };
    }

    componentDidMount = () => {
        this.checkLocation();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.location.search !== prevProps.location.search) {
            this.checkLocation();
        }
    }

    checkLocation = () => {
        if (this.props.location.search.length < 2) {
            this.resetState();
            return;
        }
        const params = qs.parse(this.props.location.search.slice(1));
        if (!(resourceConfig.urlParamName in params)) {
            this.resetState();
            return;
        }
        if (this.state.show) {
            return;
        }
        const data = paramToDictionary(params[resourceConfig.urlParamName]);

        this.setState({
            show: true,
            params: {
                ...this.defaultParams,
                ...data
            }
        });
    }

    resetState = () => {
        this.setState({
            show: false,
            executing: false,
        });
        setTimeout(this.afterClose, 500);
    }

    afterClose = () => {
        this.setState({
            params: {
                ...this.defaultParams
            }
        })
    }

    execute = async (formData) => {
        this.setState({
            executing: true
        });

        let {
            dateFrom,
            dateTo,
            ...data
        } = formData;

        if (dateFrom) {
            data.dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        }
        if (dateTo) {
            data.dateTo = moment(dateTo).format('YYYY-MM-DD');
        }

        let response = null;
        try {
            response = await request({
                apiService: resourceConfig.apiService,
                path: resourceConfig.apiPath,
                body: JSON.stringify(data),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        } catch (e) {
            console.error(e);
        }
        if (response === null || !response.ok) {
            this.props.showNotification(`${trPrefix}.errorMessage`, 'error');
        } else {
            this.props.showNotification(`${trPrefix}.successMessage`, 'success');
            this.props.history.goBack();
        }

        this.setState({
            executing: false
        });
    }

    handleCloseClick = () => {
        this.props.history.goBack();
    }


    render() {
        const {
            translate
        } = this.props;

        return (
            <Drawer
                anchor={'right'}
                open={this.state.show}
                onClose={this.handleCloseClick}
            >
                <SimpleForm
                    defaultValue={this.state.params}
                    save={this.execute}
                    toolbar={<FormToolbar/>}
                    saving={this.state.executing}
                    style={{width: 800, maxWidth: '90vw'}}
                >
                    <Typography
                        variant={'title'}
                    >
                        {translate(`${trPrefix}.title`)}
                    </Typography>
                    {/*<EnumInput source={'tail'} translatePrefix={`${trPrefix}.enums.tail.`}*/}
                    {/*           label={`${trPrefix}.fields.tail`} enums={['INBOX', 'OUTGOING', 'DRAFT']} fullWidth*/}
                    {/*           default={'INBOX'}/>*/}
                    <TextInput source={'bin'} label={`${trPrefix}.fields.bin`} fullWidth/>
                    <EnumInput source={'direction'} translatePrefix={`${trPrefix}.enums.direction.`}
                               enums={['asc', 'desc']} label={`${trPrefix}.fields.direction`} fullWidth/>
                    <DateInput source={'dateFrom'} label={`${trPrefix}.fields.dateFrom`}
                               options={{format: 'yyyy-MM-dd HH:mm:ss'}}/>
                    <DateInput source={'dateTo'} label={`${trPrefix}.fields.dateTo`}
                               options={{format: 'yyyy-MM-dd HH:mm:ss'}}/>
                    {/*<EnumInput source={'status'} fullWidth label={`${trPrefix}.fields.status`}*/}
                    {/*           translatePrefix={`${trPrefix}.enums.status.`}*/}
                    {/*           enums={['DRAFT', 'REJECTED', 'ACCEPTED', 'СULC_RESULTS', 'WITHDRAWAN', 'CHECK_ERROR', 'W_VERIFICATION']}/>*/}
                    {/*<EnumInput source={'typeDoc'} translatePrefix={`${trPrefix}.enums.typeDoc.`}*/}
                    {/*           enums={['ONE_DOC_TYPE', 'TEN_DOC_TYPE', 'SIX_DOC_TYPE', 'SEVEN_DOC_TYPE']}*/}
                    {/*           label={`${trPrefix}.fields.typeDoc`} fullWidth/>*/}
                    <EnumInput source={'sortBy'} translatePrefix={`${trPrefix}.enums.sortBy.`}
                               enums={['status', 'sender_bin', 'receiver_bin']} label={`${trPrefix}.fields.sortBy`}
                               fullWidth/>
                    <NumberInput source={'rowCount'} fullWidth label={`${trPrefix}.fields.rowCount`}/>
                </SimpleForm>
            </Drawer>
        )
    }
}

export default compose(
    withRouter,
    connect(null, {showNotification}),
    translate,
    withStyles(styles)
)(AppModule);
