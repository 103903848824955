import React, { Fragment } from 'react';
import {
  BulkDeleteButton,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  translate,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';
import DateInput from '../../../../components/DateInput';
import resourceLocales from './locales';
import resourceConfig from './config';
import LinkToList from './LinkToList';
import mrkStatusSystem from '../mrkStatusSystem';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import emissionType from '../emissionType';
import LoadButton from './LoadButton';
import UpdateKmButton from './UpdateKmButton';
import treeMrkLoadCustom from '../treeMrkLoadCustom';
import exporter from '../../../../utils/exporter';
import UpdateKmAllButton from './UpdateKmAllButton';
import BulkUpdateAction from './BulkUpdateAction';
import PostPagination from '../../../../utils/pagination';
import markingStatus from '../markingStatus';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x = {}) => `#${x.id}`;

const listStyles = {};

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <TextInput source={'cis'} />
    <TextInput source={'cises'} />
    <DateInput
      source={'emissionDateFrom'}
      label={`resources.${resourceConfig.name}.filters.emissionDateFrom`}
    />
    <DateInput
      source={'emissionDateTo'}
      label={`resources.${resourceConfig.name}.filters.emissionDateTo`}
    />
      <DateInput
        source={'emissionDate'}
        label={`resources.${resourceConfig.name}.filters.emissionDate`}
      />
      <emissionType.Input source={'emissionType'} />
      <TextInput source={'orderNum'} />
      <TextInput source={'contactPerson'} />
      <TextInput source={'tnved'} />
      <TextInput source={'gtin'} />
      <TextInput source={'sgtin'} />
      <TextInput source={'numdoc'} />
      <TextInput source={'itemCode'} />
      <TextInput source={'productName'} />
      <DateInput source={'producedDate'} showTime />
      <TextInput source={'packageType'} />
      <TextInput source={'ownerBin'} />
      <TextInput source={'ownerName'} />
      <mrkStatusSystem.Input source={'mrkSystemStatus'} />
      <DateInput source={'statusChangeDate'} showTime />
      <markingStatus.Input source={'status'} />
      <TextInput source={'producerBin'} />
      <TextInput source={'producerName'} />
      <TextInput source={'parent'} />
      <TextInput source={'child'} />
      <TextInput source={'partyNum'} />
      <DateInput source={'limitDate'} showTime />
      <TextInput source={'dosageForm'} />
      <TextInput source={'releaseForm'} />
      <TextInput source={'dosage'} />
  </Filter>
);

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <BulkUpdateAction {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const ResourceListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <treeMrkLoadCustom.OpenButton />
    <UpdateKmAllButton />
    <LoadButton />
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    actions={<ResourceListActions />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />
      <TextField source={'gtin'} />
      <TextField source={'sgtin'} />
      <TextField source={'parent'} />
      <emissionType.Field source={'emissionType'} />
      <mrkStatusSystem.Field source={'mrkSystemStatus'} />
      <markingStatus.Field source={'status'} />
      <TextField source={'ownerBin'} />
      <UpdateKmButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'cis'} />
      <DateInput
        source={'emissionDate'}
        label={`resources.${resourceConfig.name}.filters.emissionDate`}
      />
      <emissionType.Input source={'emissionType'} />
      <TextInput source={'orderNum'} />
      <TextInput source={'contactPerson'} />
      <TextInput source={'tnved'} />
      <TextInput source={'gtin'} />
      <TextInput source={'sgtin'} />
      <TextInput source={'numdoc'} />
      <TextInput source={'itemCode'} />
      <TextInput source={'productName'} />
      <DateInput source={'producedDate'} showTime />
      <TextInput source={'packageType'} />
      <TextInput source={'ownerBin'} />
      <TextInput source={'ownerName'} />
      <mrkStatusSystem.Input source={'mrkSystemStatus'} />
      <DateInput source={'statusChangeDate'} showTime />
      <markingStatus.Input source={'status'} />
      <TextInput source={'producerBin'} />
      <TextInput source={'producerName'} />
      <TextInput source={'parent'} />
      <TextInput source={'child'} />
      <TextInput source={'partyNum'} />
      <DateInput source={'limitDate'} showTime />
      <TextInput source={'dosageForm'} />
      <TextInput source={'releaseForm'} />
      <TextInput source={'dosage'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} title={<ResourceTitle />} {...props}>
    <SimpleForm>
      <TextField source={'id'} />
      <TextInput source={'cis'} />
      <DateInput
        source={'emissionDate'}
        label={`resources.${resourceConfig.name}.filters.emissionDate`}
      />
      <emissionType.Input source={'emissionType'} />
      <TextInput source={'orderNum'} />
      <TextInput source={'contactPerson'} />
      <TextInput source={'tnved'} />
      <TextInput source={'gtin'} />
      <TextInput source={'sgtin'} />
      <TextInput source={'numdoc'} />
      <TextInput source={'itemCode'} />
      <TextInput source={'productName'} />
      <DateInput source={'producedDate'} showTime />
      <TextInput source={'packageType'} />
      <TextInput source={'ownerBin'} />
      <TextInput source={'ownerName'} />
      <mrkStatusSystem.Input source={'mrkSystemStatus'} />
      <DateInput source={'statusChangeDate'} showTime />
      <markingStatus.Input source={'status'} />
      <TextInput source={'producerBin'} />
      <TextInput source={'producerName'} />
      <TextInput source={'parent'} />
      <TextInput source={'child'} />
      <TextInput source={'partyNum'} />
      <DateInput source={'limitDate'} showTime />
      <TextInput source={'dosageForm'} />
      <TextInput source={'releaseForm'} />
      <TextInput source={'dosage'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      emissionDate: {
        type: 'dateTime',
      },
      emissionDateFrom: {
        type: 'dateTime',
      },
      emissionDateTo: {
        type: 'dateTime',
      },
      producedDate: {
        type: 'dateTime',
      },
      statusChangeDate: {
        type: 'dateTime',
      },
      limitDate: {
        type: 'dateTime',
      },
    },
  },
};
