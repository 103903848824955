import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'IN_PROGRESS': 'Проверяется',
        'CHECKED_OK': 'Обработан',
        'CHECKED_NOT_OK': 'Обработан с ошибками',
        'PROCESSING_ERROR': 'Техническая ошибка',
        'CANCELLED': 'Аннулирован',
        'ACCEPTED': 'Принят',
        'WAIT_ACCEPTANCE': 'Ожидание приемку',
        'WAIT_PARTICIPANT_REGISTRATION': 'Ожидает регистрации участника в ГИС МТ',
        'UNDEFINED': 'Не определен',
        'PENDING': 'Ожидание',
        'READY_TO_SEND': 'Готов к отправке',
        'REJECTED': 'Отклонен',
        'SENT': 'Отправлен',
      },
    }
  }
}