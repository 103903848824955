import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import AddIcon from '@material-ui/icons/Add';
// import SyncIcon from '@material-ui/icons/Sync';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
// import { Link } from 'react-router-dom';


const styles = {
    media: {
        height: '18em',
    },
};

const mediaUrl = 'https://source.unsplash.com/2RraPpR-6Tc/1600x900';

const Welcome = ({ classes, translate }) => (
    <Card>
        <CardMedia image={mediaUrl} className={classes.media} />
        <CardContent>
            <Typography variant="h5" component="h2">
                {translate('pos.dashboard.welcome.title')}
            </Typography>
            <Typography component="p">
                {translate('pos.dashboard.welcome.subtitle')}
            </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
            {/* <Button component={Link} to={'/?docpurchase='}>
                <SyncIcon style={{ paddingRight: '0.5em' }} />
                {translate('pos.dashboard.welcome.fetchDocumentsButton')}
            </Button>
            <Button component={Link} to={'/docH/create'}>
                <AddIcon style={{ paddingRight: '0.5em' }} />
                {translate('pos.dashboard.welcome.createDocumentButton')}
            </Button> */}
        </CardActions>
    </Card>
);

export default withStyles(styles)(translate(Welcome));
