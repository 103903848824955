import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        NEW: 'NEW',
        SENDED: 'SENDED',
        SIGN: 'SIGN',
        ERROR: 'ERROR',
        PRICE_ADDED: 'PRICE_ADDED',
        PRICE_NOT_ADDED: 'PRICE_NOT_ADDED',
        CORR_FORMED: 'CORR_FORMED',
      },
    },
  },
};
