import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Sync',
      title: 'Sync',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Success',
      fields: {
        tail: 'tail',
        bin: 'bin',
        direction: 'direction',
        typeDoc:'typeDoc',
        dateFrom: 'dateFrom',
        state:'state',
        sortBy:'sortBy',
        rowCount:'rowCount',
        dateTo: 'dateTo',
        did: 'did',
        number: 'number',
        documentFormat: 'documentFormat',
        documentStatus: 'documentStatus',
        documentType: 'documentType',
        inputFormat: 'inputFormat',
        participantInn: 'participantInn',
        limit: 'limit',
        order: 'order',
        orderColumn: 'orderColumn',
        orderedColumnValue: 'orderedColumnValue',
        pageDir: 'pageDir',
        senderInn: 'senderInn',
        receiverInn: 'receiverInn',
        status: 'status'
      },
      enums:{
        status:{
          DRAFT:'DRAFT',
          REJECTED:'REJECTED',
          ACCEPTED:'ACCEPTED',
          СULC_RESULTS:'СULC_RESULTS',
          CHECK_ERROR:'CHECK_ERROR',
          W_VERIFICATION:'W_VERIFICATION',
        },
        tail:{
          INBOX:'inbox',
          OUTGOING:'outgoing',
          DRAFT:'draft',
        },
        direction:{
          asc:'asc',
          desc:'desc',
        },
        sortBy:{
          status:'satus',
          sender_bin:'sender_bin',
          receiver_bin:'receiver_bin'
        },
        typeDoc:{
          ONE_DOC_TYPE: 'ONE_DOC_TYPE',
          TEN_DOC_TYPE: 'TEN_DOC_TYPE',
          SIX_DOC_TYPE: 'SIX_DOC_TYPE',
          SEVEN_DOC_TYPE: 'SEVEN_DOC_TYPE',
        },
        documentType: {
          LP_SHIP_GOODS: 'LP_SHIP_GOODS',
          LP_ACCEPT_GOODS: 'LP_ACCEPT_GOODS',
          EAS_CROSSBORDER_EXPORT: 'EAS_CROSSBORDER_EXPORT',
        },
        documentStatus:{
          IN_PROGRESS: 'IN_PROGRESS',
          CHECKED_OK: 'CHECKED_OK',
          CHECKED_NOT_OK: 'CHECKED_NOT_OK',
          PROCESSING_ERROR: 'PROCESSING_ERROR',
          CANCELLED: 'CANCELLED',
          ACCEPTED: 'ACCEPTED',
          WAIT_ACCEPTANCE: 'WAIT_ACCEPTANCE',
          WAIT_PARTICIPANT_REGISTRATION: 'WAIT_PARTICIPANT_REGISTRATION',
          PARSE_ERROR: 'PARSE_ERROR',
          WAIT_FOR_CONTINUATION: 'WAIT_FOR_CONTINUATION'
        }
      }

    }
  }
}