import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции актов |||| Позиции актов',

      fields: {
        id: '#',
        sntin: 'Код маркировки',
      },
    },
  },
};
