import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        ONE_DOC_TYPE:"Single Distributor Acceptance Certificate",
        SEVEN_DOC_TYPE:"Single Distributor Internal Transfer Certificate",
        SIX_DOC_TYPE:"Internal Transfer Act", 
        FOUR_DOC_TYPE:"Withdrawal From Circulation",
        FIVE_DOC_TYPE:"Return Into Circulation",
        TWO_DOC_TYPE:"EAEU Import", 
        THREE_DOC_TYPE:"Third Countries Import", 
        ELEVEN_DOC_TYPE:"Transfer Report from non RK-resident",
        SIX_THOUSAND_DOC_TYPE:"Aggregation out of production", 
        TEN_DOC_TYPE:"Acceptance Act" 
      },
    }
  }
}





