import React from 'react';
import qs from 'qs';
import {
    translate,
    Datagrid,
    Edit,
    List,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput, 
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    Create,
    EditButton,
    CreateButton,
    ExportButton,
    NumberField,
    BooleanField,
    NumberInput,
    BooleanInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import resourceLocales from './locales';
import resourceConfig from './config';
import LinkToList from './LinkToList';
// import orderP from '../orderP';
import requestStatus from '../requestStatus';
import exporter from '../../../../utils/exporter';

const ResourceIcon = BookmarkIcon;

const listStyles = {
};

const ResourceActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    translate
}) => (
    <Toolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton
            basePath={basePath}
            to={`${basePath}/create?${filterValues && filterValues.overEntityIds ? `_orderPId=${filterValues.overEntityIds}` : ''}`}
        />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </Toolbar>
);

const ResourceListFilter = (props) => (
    <Filter {...props}>
        {/* <orderP.Input source="overEntityIds" label="overEntityIds" /> */}
    </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        filters={<ResourceListFilter />}
        actions={<ResourceActions />}
        exporter={exporter}
    >
        <Datagrid>
            <TextField source={'id'}  />
            {/* <orderP.Field source={'orderPId'} /> */}
            <TextField source={'poolInfos'} />
            <NumberField source={'quantity'} />
            <NumberField source={'registrarErrorCount'} />
            <TextField source={'registrarId'} />
            <BooleanField source={'registrarReady'} />
            <requestStatus.Field source={'REQUEST_ERROR'} />
            <EditButton />
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({ record, translate }) => (
    <span>
        {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{
            record.id
        }&quot;
    </span>
));


const ResourceCreate = props => {
    const defaultData = {};
    if (props.location && props.location.search && props.location.search.length > 1) {
        const params = qs.parse(props.location.search.replace(/^\?/, ''));
        if (params && params._orderPId) {
            defaultData.orderPId = parseInt(params._orderPId);
        }
    }
    return (
        <Create {...props}>
            <SimpleForm>
                {/* <orderP.Input source={'orderPId'} defaultValue={defaultData.orderPId} /> */}
                <NumberInput source={'lastRegistrarErrorTimestamp'} />
                <NumberInput source={'leftInRgistrar'} />
                <TextInput source={'poolInfos'} />
                <NumberInput source={'quantity'} />
                <NumberInput source={'registrarErrorCount'} />
                <TextInput source={'registrarId'} />
                <BooleanInput source={'registrarReady'} />
                <TextInput source={'rejectionReason'} />
                <requestStatus.Input source={'REQUEST_ERROR'} />
            </SimpleForm>
        </Create>
    )
}

const ResourceEdit = props => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
        <SimpleForm>
            <TextField source={'id'} />
            {/* <orderP.Input source={'orderPId'} /> */}
            <NumberInput source={'lastRegistrarErrorTimestamp'} />
            <NumberInput source={'leftInRgistrar'} />
            <TextInput source={'poolInfos'} />
            <NumberInput source={'quantity'} />
            <NumberInput source={'registrarErrorCount'} />
            <TextInput source={'registrarId'} />
            <BooleanInput source={'registrarReady'} />
            <TextInput source={'rejectionReason'} />
            <requestStatus.Input source={'REQUEST_ERROR'} />
        </SimpleForm>
    </Edit>
);



const ResourceReferenceField = (props) => (
    <ReferenceField
        reference={resourceConfig.name}
        linkType={'show'}
        {...props}
    >
        <TextField source={'id'} />
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField
        reference={resourceConfig.name}
        linkType={'show'}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'id'} />
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectInput optionText={'id'} />
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput 
      reference={resourceConfig.name} 
      {...props}
    >
      <SelectArrayInput optionText={'id'} />
    </ReferenceArrayInput>
);

export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Edit: ResourceEdit,
    Create: ResourceCreate,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    LinkToList,
    
    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        // show: ResourceShow,
        icon: ResourceIcon,
        edit: ResourceEdit,
        create: ResourceCreate,
    },
    locales: resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {
        }
    }
}
