import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        ONE_DOC_TYPE: "Акт приема передачи маркированного товара Единого дистрибьютора",
        TEN_DOC_TYPE:
            "Акт приема/передачи",
        SEVEN_DOC_TYPE:
            "Акт внутреннего перемещения Единого дистрибьютора",
        SIX_DOC_TYPE:
            "Акт внутреннего перемещения",
        FOUR_DOC_TYPE:
            "Уведомление о выводе из оборота",
        FIVE_DOC_TYPE:
            "Уведомление о повторном вводе в оборот",
        TWO_DOC_TYPE:
            "Уведомление о ввозе из стран ЕАЭС",
        THREE_DOC_TYPE:
            "Уведомление о ввозе из третьих стран",
        ELEVEN_DOC_TYPE:
            "Отчет о передаче КИ от нерезидента РК",
        SIX_THOUSAND_DOC_TYPE:
            "Агрегация вне производства",
      },
    },
  },
};
