import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Notification |||| Notifications',
      fields: {
        email: 'Email',
      }
    }
  }
}