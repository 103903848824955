import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ввод в оборот',
      inputP: 'Позиции',
      actPrice: 'Цена товаров',
      sendDoc: {
        action: 'Отправить',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      validateDoc: {
        action: 'Валидация',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      checkDocs: {
        action: 'Проверить статус',
        successMessage: 'Успешно',
        errorMessage: 'Ошибка',
      },
      enums: {
        usageType: {
          USED_FOR_PRODUCTION: 'Использован',
          SENT_TO_PRINTER: 'Отправлен на печать',
          PRINTED: 'Напечатан',
          PRINTER_LOST: 'Утерян',
          VERIFIED: 'Подтвержден',
        }
      },
      fields: {
        id: 'Id',
        direction: 'Направление',
        guid: 'guid',
        usageType: 'Тип использования',
        expirationDate: 'Срок годности',
        seriesNumber: 'Номер серии',
        productionOrderId: 'Производственный заказ',
        brandcode: 'Брэнд',
        sourceReportId: 'Номер отчета из АСУТП',
        status: 'Системный статус',
        checkStatus: 'Статус ЦЭДМ',
        dateSend: 'Дата отправки документа',
        idDoc:'Идентификатор документа',
        docType: 'Тип документа',
        aggrType:'Вид документа агрегации',
        numdoc:'Номер связующего документа',
        senderBin:'БИН отправителя',
        senderName:'Наименование отправителя',
        receiverBin:'БИН получателя',
        ownerBin:'БИН владельца кодов',
        paperNum:'Номер первичного документа',
        paperDate:'Дата первичного документа',
        comment:'Текст примечания',
        addInfo:'Массив данных дополнительной  информации',
        reasonWithdrawal:'Причина вывода из оборота',
        docName:'Наименование документа основания',
        docNum:'Номер документа основания',
        docDate:'Дата документа  основания',
        countryCodeEAEU:'Страна отправления',
        countryCode:'Страна экспорта',
        docTypeCustom:'Тип документа таможенного оформления',
        regNumDoc:'Регистрационный номер документа таможенного оформления',
        regDateDoc:'Дата регистрации документа таможенного оформления',
        decisionCode:'Код принятого решения документа таможенного оформления',
        decisionDate:'Дата и время решения документа таможенного оформления',
        customsCode:'Код таможенного органа документа таможенного оформления',
        documentType:'Тип документа, подтверждающего соответствие товаров',
        documentNum:'Номер документа, подтверждающего соответствие товаров',
        documentDate:'Дата документа, подтверждающего соответствие товаров',
        shipmentDate:'Дата отгрузки',
        reasonIntroduce:'Причина ввода в оборот',
        errorMessage:'Описание ошибки',
        validated:'Валидация',
        reasonIntroduced: 'Причина ввода'
      },
    },
  },
};
