import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Doc edo P |||| Doc edo P records',
      importThirdCountryM: 'M positions',
      aggrM: 'M positions', 
      fields: {
        id: 'ID',
      },
    },
  },
};
