import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BulkDeleteButton,
  EditButton,
  Filter,
  CreateButton,
  ExportButton,
  NumberInput,
  BooleanField,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyleByCheckStatus';
import DateInput from '../../../../components/DateInput';
import exporter from '../../../../utils/exporter';
import CheckDocs from './CheckDocs';
import usageType from '../usageType';
import errorModel from '../errorModel';
import objectSendModel from '../objectSendModel';
import directionType from '../directionType'
import SendInput from './SendInput';
import aggrP from '../aggrP';
import documentImport from '../documentImport'
import checkStatus from '../checkStatus';
import status from '../status';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id}`;

const listStyles = {};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}

    <CreateButton basePath={basePath} to={`${basePath}/create`} />
    <documentImport.OpenButton params={{ docType: 'AGGR' }} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </Toolbar>
);

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <CheckDocs {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const PButton = (props) => {
  return (
    <aggrP.LinkToList
      label={`resources.${resourceConfig.name}.aggrP`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <checkStatus.Input source={'checkStatus'}/>
    <DateInput source={'dateSend'}/>
    <TextInput source={'idDoc'} />
    <TextInput source={'senderBin'} />
    <status.Input source={'status'}/>
    <TextInput source={'numdoc'} />
    <TextInput source={'aggregateCode'} />
    <TextInput source={'packageType'} />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    actions={<ResourceActions />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    sort={{ field: 'id', order: 'DESC' }}
    exporter={exporter}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />
      <TextField source={'idDoc'} />
      <TextField source={'senderBin'} />
      <TextField source={'docNum'} />
      <TextField source={'docDate'} />
      <status.Field source={'status'} />
      <checkStatus.Field source={'checkStatus'} />
      <TextField source={'numdoc'} />
      <TextField source={'packageType'} />

      <BooleanField source={'validated'} />
      <errorModel.LinkToRelatedList type={'SIX_THOUSAND_DOC_TYPE'} />
      <objectSendModel.LinkToRelatedList type={'SIX_THOUSAND_DOC_TYPE'} />
      <PButton />
      <SendInput/>
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <directionType.Input source={'direction'} />
      <NumberInput source={'idDoc'} />
      <TextInput source={'docType'} />
      <checkStatus.Input source={'checkStatus'} />
      <status.Input source={'status'} />
      <DateInput
        source={'dateSend'}
        label={`resources.${resourceConfig.name}.fields.dateSend`}
      />
      <TextInput source={'senderBin'} />
      <TextInput source={'senderName'} />
      <TextInput source={'receiverBin'} />
      <TextInput source={'ownerBin'} />
      <TextInput source={'paperNum'} />
      <DateInput
        source={'paperDate'}
        label={`resources.${resourceConfig.name}.fields.paperDate`}
      />
      <TextInput source={'comment'} />
      <TextInput source={'addInfo'} />
      <TextInput source={'reasonWithdrawal'} />
      <TextInput source={'docName'} />
      <TextInput source={'docNum'} />
      <DateInput
        source={'docDate'}
        label={`resources.${resourceConfig.name}.fields.docDate`}
      />
      <TextInput source={'countryCodeEAEU'} />
      <TextInput source={'countryCode'} />
      <TextInput source={'docTypeCustom'} />
      <TextInput source={'regNumDoc'} />
      <DateInput
        source={'regDateDoc'}
        label={`resources.${resourceConfig.name}.fields.regDateDoc`}
      />
      <TextInput source={'decisionCode'} />
      <DateInput
        source={'decisionDate'}
        label={`resources.${resourceConfig.name}.fields.decisionDate`}
      />
      <TextInput source={'customsCode'} />
      <TextInput source={'documentType'} />
      <TextInput source={'documentNum'} />
      <DateInput
        source={'documentDate'}
        label={`resources.${resourceConfig.name}.fields.documentDate`}
      />
      <DateInput
        source={'shipmentDate'}
        label={`resources.${resourceConfig.name}.fields.shipmentDate`}
      />
      <TextInput source={'reasonIntroduce'} />
      <TextInput source={'aggrType'} />
      <TextInput source={'packageType'} />
      <usageType.Input source={'usageType'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} title={<ResourceTitle />} {...props}>
    <SimpleForm>
      <directionType.Input source={'direction'} />
      <NumberInput source={'idDoc'} />
      <TextInput source={'docType'} />
      <TextInput source={'checkStatus'} />
      <TextInput source={'status'} />
      <DateInput
        source={'dateSend'}
        label={`resources.${resourceConfig.name}.fields.dateSend`}
      />
      <TextInput source={'senderBin'} />
      <TextInput source={'senderName'} />
      <TextInput source={'receiverBin'} />
      <TextInput source={'ownerBin'} />
      <TextInput source={'paperNum'} />
      <DateInput
        source={'paperDate'}
        label={`resources.${resourceConfig.name}.fields.paperDate`}
      />
      <TextInput source={'comment'} />
      <TextInput source={'addInfo'} />
      <TextInput source={'reasonWithdrawal'} />
      <TextInput source={'docName'} />
      <TextInput source={'docNum'} />
      <DateInput
        source={'docDate'}
        label={`resources.${resourceConfig.name}.fields.docDate`}
      />
      <TextInput source={'countryCodeEAEU'} />
      <TextInput source={'countryCode'} />
      <TextInput source={'docTypeCustom'} />
      <TextInput source={'regNumDoc'} />
      <DateInput
        source={'regDateDoc'}
        label={`resources.${resourceConfig.name}.fields.regDateDoc`}
      />
      <TextInput source={'decisionCode'} />
      <DateInput
        source={'decisionDate'}
        label={`resources.${resourceConfig.name}.fields.decisionDate`}
      />
      <TextInput source={'customsCode'} />
      <TextInput source={'documentType'} />
      <TextInput source={'documentNum'} />
      <DateInput
        source={'documentDate'}
        label={`resources.${resourceConfig.name}.fields.documentDate`}
      />
      <DateInput
        source={'shipmentDate'}
        label={`resources.${resourceConfig.name}.fields.shipmentDate`}
      />
      <TextInput source={'reasonIntroduce'} />
      <TextInput source={'aggrType'} />
      <TextInput source={'packageType'} />
      <usageType.Input source={'usageType'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      expirationDate: {
        type: 'dateTime',
      },
      dateSend: {
        type:'dateTime'
      }
    },
  },
};
