import React, { Fragment } from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  ShowButton,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import resourceConfig from './config';
import resourceLocales from './locales';
import docLink from '../docLink';
import docLinkType from '../docLinkType';
import funcType from '../funcType';
import LinkToList, { LinkToRelatedList } from './LinkToList';
import exporter from '../../../../utils/exporter';
import PostPagination from '../../../../utils/pagination';


const ResourceIcon = BookmarkIcon;

const listStyles = {};

const ResourceBulkActionButtons = (props) => (
  <Fragment>
    <div></div>
    {/* <BulkDeleteButton {...props} /> */}
  </Fragment>
);

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'ids'} />
    <TextInput source={'documentLinkId'} />
    <docLinkType.Input source={'documentLinkType'} />
  </Filter>
);

const ResourceList = withStyles(listStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<ResourceListFilter />}
    bulkActionButtons={<ResourceBulkActionButtons />}
    exporter={exporter}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<PostPagination />}>
    <Datagrid>
      <TextField source={'id'} />
      <TextField source={'createdDate'} />
      <funcType.Field source={'functionType'} />
      <docLink.Field />
      <TextField source={'shortMessage'} />
      <ShowButton />
      {/* <EditButton /> */}
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.name}
    &quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm></SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm></SimpleForm>
  </Edit>
);

const ResourceShow = (props) => (
  <Show {...props} title={<ResourceTitle />}>
    <SimpleShowLayout>
      <TextField source={'id'} />
      <TextField source={'createdDate'} />
      <docLink.Field />
      <TextField source={'shortMessage'} />
      <TextField source={'message'} />

      <funcType.Field source={'functionType'} />
      <TextField source={'httpClientMess'} />
    </SimpleShowLayout>
  </Show>
);

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <TextField source={'id'} />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={'id'} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={'id'} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,
  LinkToList: LinkToList,
  LinkToRelatedList: LinkToRelatedList,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    // edit: ResourceEdit,
    // create: ResourceCreate,
    show: ResourceShow,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {},
  },
};
