import OpenButton from './OpenButton';
import AppModule from './AppModule';
import resourceLocales from './locales';
import resourceConfig from './config';

export default {
    AppModule,
    OpenButton,

    name: resourceConfig.name,
    locales: resourceLocales,
}
