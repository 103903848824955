import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Doc edo',
      docEdoP: 'Positions',
      sendDoc: {
        action: 'Send doc',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      checkDocs: {
        action: 'Check docs',
        successMessage: 'Success',
        errorMessage: 'Error',
      },
      enums: {
        usageType: {
          USED_FOR_PRODUCTION: 'USED_FOR_PRODUCTION',
          PRINTED: 'PRINTED',
          PRINTER_LOST: 'PRINTER_LOST',
          VERIFIED: 'VERIFIED',
          SENT_TO_PRINTER: 'SENT_TO_PRINTER',
        },
      },
      fields: {
        id: 'Id',
        guid: 'guid',
        usageType: 'usage type',
        expirationDate: 'expiration date',
        seriesNumber: 'series number',
        productionOrderId: 'production order id',
        status: 'Status',
      },
    },
  },
};
