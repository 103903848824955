import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиции актов |||| Позиции актов',
      actM: 'Подпозиции',
      fields: {
        id: '#',
        sntin: 'Код маркировки',
      },
    },
  },
};
