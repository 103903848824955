import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'RECONCILIATION',
      title: 'RECONCILIATION',
      executeAction: 'Send',
      resetAction: 'Reset',
      errorMessage: 'Error',
      successMessage: 'Success',
      fields: {
        file: 'File',
        label: 'Template',
      },
    }
  }
}